import { mainnet } from 'wagmi/chains'
import useSWR from 'swr'
import { useAccount } from 'wagmi'
import { truncateAddress, truncateEns } from 'utils/truncate'

export default (address?: string, chainId: number = 1) => {
  const { address: accountAddress } = useAccount()
  const addressLowercase = address?.toLowerCase()
  const isENSAvailable = chainId === mainnet.id
  const isAccountAddress =
    accountAddress && address?.toLowerCase() === accountAddress?.toLowerCase()

  const response = useSWR(
    `https://api.ensideas.com/ens/resolve/${addressLowercase}`,
    async (url: string) => {
      if (!isENSAvailable || !address) {
        return null
      }
      try {
        const response = await fetch(url)
        if (!response.ok) {
          throw new Error('Failed to fetch ENS data')
        }
        return response.json()
      } catch (error) {
        // Throw error to be caught by SWR
        throw error
      }
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false, // Prevent retrying on error
      errorRetryCount: 0, // Set max retry count to 0
    }
  )

  const shortAddress = address ? truncateAddress(address) : ''
  const shortName = response.data?.name
    ? response.data?.name === address
      ? truncateAddress(response.data.name)
      : truncateEns(response.data.name)
    : null
  let displayName = ''

  if (isAccountAddress) {
    displayName = 'You'
  } else if (response.data?.name) {
    displayName = shortName || ''
  } else if (address) {
    displayName = shortAddress
  }

  return {
    ...response,
    address,
    name: response.data?.name,
    shortName,
    displayName,
    shortAddress,
    bannerImage: response.data?.banner_image,
    avatar: response.data?.profile_image,
    bio: response.data?.bio,
    twitterHandle: response.data?.twitterHandle,
  }
}
